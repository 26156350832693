@import "src/shared/assets/styles/main.scss";

.tooltip {
  @include listMainText;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 176px;
  padding: 12px;
}

.building,
.status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.status img {
  padding-right: 4px;
}

.citiesImg {
  margin-right: 7px;
}

.caption {
  color: $bw-gray6;
  padding-right: 4px;
}

.bage {
  border-radius: 4px;
  @include mainText;
  color: $bw-white;
  padding: 2px 4px;
  margin-left: 4px;
}
