@import "shared/assets/styles/base/colors.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container {
  width: 38.55vw;
}

.disabledField {
  & textarea,
  input {
    background-color: $bw-white !important;
    cursor: pointer !important;
  }
}

.backIcon {
  fill: none !important;
  stroke: $blue-lazure;
}
