@import "src/shared/assets/styles/mixins/mainMixins.scss";

.tabsButtons {
  & > :last-child {
    margin-left: auto;
  }
}

.emptyAlert {
  margin: 20px 0;
}
