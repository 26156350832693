@import "src/shared/assets/styles/main.scss";

%border {
  border: 1px solid $bw-gray3;
  &:first-child {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }
}

.isLoadingForTable {
  width: 100%;
  height: calc(100vh - 225px);
}

.table {
  margin-top: 225px !important;
}

.stickyHeader {
  position: sticky;
  top: 250px;
  background-color: $bw-white !important;
  &__shadow {
    box-shadow: 1px 3px 3px 0 rgb(231, 231, 231) !important;
  }
}

.title {
  @include caption;
  padding: 8px !important;
  vertical-align: middle;
  @extend %border;
  &__link {
    color: $blue-lazure;
    cursor: pointer;
  }
}

.iconQuestion {
  cursor: pointer;
  & > rect,
  path {
    fill: $blue-lazure;
  }
  & > circle {
    stroke: $blue-lazure;
  }
}

.tableBody {
  @include mainText;
  & > tr {
    vertical-align: middle;
    cursor: pointer;
    &:hover {
      background-color: $blue-white-blue;
    }

    & td:nth-child(2),
    td:nth-child(3) {
      min-width: 550px;
      width: 550px;
    }

    & > td {
      padding: 10px;
      min-height: 60px;
      height: 60px;
      @extend %border;
    }
  }
}

.greyRow {
  background-color: $bw-gray1;
}

.selectedRow {
  background-color: $blue-white-blue;
}

.rankNum {
  min-width: 92px;
  width: 92px;
  text-align: center;
}

.link {
  color: $blue-lazure;
  cursor: pointer;
  text-decoration: none;
}

.starRank {
  display: flex;
  align-items: center;
  gap: 5px;
}

.avatar {
  min-width: 35px;
  width: 35px;
  min-height: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.tooltip {
  position: relative;
  @include tooltip("bottom-end", $bw-gray7, $bw-white, $bw-gray7, 250px);
}

.emptyTH {
  width: 40px;
}
