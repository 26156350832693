@import "src/shared/assets/styles/main.scss";

.tableWrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.scrollButton {
  & > div {
    position: relative;
    display: flex;
  }
}

.leftButton,
.rightButton {
  position: sticky !important;
  top: 0;
  height: auto;
  margin: 45px 0 33px 0;
}

.leftButton {
  left: 42px;
}

.table {
  tbody {
    border-top: none !important;
    border-bottom: none !important;
    tr > td > p {
      @include zero;
      @include listMainText;
    }
    & > tr > td {
      padding: 8px;
      vertical-align: top;
      border: solid 1px $bw-gray3;
      &.companyName {
        @include listMainText;
        writing-mode: vertical-lr;
        text-align: center;
        background-color: $bw-white;
        position: sticky;
        left: 0;
        z-index: 1;

        & div {
          transform: rotate(180deg);
          margin-left: 3px;
        }
        &::after {
          content: " ";
          height: 100%;
          position: absolute;
          top: 0;
          width: 15px;
          color: $bw-gray3;
        }
        &_shadow::after {
          box-shadow: -13px 0 13px -15px inset;
          left: -15px;
        }
      }
    }

    a {
      @include subtitle;
      font-weight: 400;
      text-decoration: none;
      cursor: pointer;
      color: $blue-lazure;
      margin-left: 10px;

      &:hover {
        color: $blue-dark-blue;
        text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }
  tfoot > tr > td {
    border: none !important;
    visibility: hidden;
  }
}

.buildingRow {
  background-color: $accent-lime;
}

.building {
  display: flex;
  align-items: center;
}

.buildingTitle {
  text-decoration: none;
}

.citiesImg {
  margin: 0 23px 0 7px;
}

.buildingStatusDate {
  @include mainText;
  color: $bw-gray6;
  margin: 2px !important;
}

.buildingRowStatuses {
  & > td {
    padding: 0 !important;
  }
}

.windowContainer {
  @include backgroundModal;
}

.window {
  position: absolute;
  z-index: 20;
}

.diff {
  border: 1px solid $bw-gray3;
  background-color: $bw-gray1 !important;
  text-align: center;
}
