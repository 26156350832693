@import "shared/assets/styles/main.scss";

@mixin appsettings($color1, $color2) {
  & circle {
    fill: $color2 !important;
  }
}

@mixin arrows($color1, $color2) {
  & > path:first-of-type {
    fill: none;
    stroke: $color1;
  }

  & > :nth-child(2),
  & > :nth-child(3) {
    fill: $color1;
    stroke: none;
  }
}
@mixin attention($color1, $color2) {
  & path {
    fill: none;
    stroke: $color1;
  }

  & > :last-child {
    stroke: none;
    fill: $color1;
  }
}
@mixin circlecheck($color1, $color2) {
  & path,
  & circle {
    fill: none;
    stroke: $color1;
  }
}
@mixin coins($color1, $color2) {
  & > :nth-last-child(-n + 2) {
    fill: $color2;
  }
}
@mixin delta($color1, $color2) {
  & > :first-child {
    fill: $color1;
  }

  & > :last-child {
    stroke: $color1;
    fill: none;
  }
}
@mixin dontсall($color1, $color2) {
  & > :nth-child(2) {
    fill: $color2 !important;
  }
}
@mixin iconarrowleft($color1, $color2) {
  & path {
    fill: none;
    stroke: $color1;
  }
}
@mixin iconcalendar($color1, $color2) {
  & path {
    fill: none;
    stroke: $color1;
  }

  & > :last-child {
    fill: $color1;
  }
}

@mixin iconcheck($color1, $color2) {
  & path {
    fill: none;
    stroke: $color1;
  }
}

@mixin iconcollapsestroke($color1, $color2) {
  & path,
  & circle {
    fill: none;
    stroke: $color1;
  }
}

@mixin iconfinance($color1, $color2, $color3) {
  & rect,
  & circle {
    fill: none;
  }

  & > g > :nth-child(2) {
    fill: $color1;
  }

  & > g > :nth-child(3) {
    fill: $color3;
  }
}

@mixin iconhistory($color1, $color2) {
  & path {
    fill: $color1 !important;
  }
}

@mixin iconprinter($color1, $color2) {
  & > :nth-child(2),
  & > :last-child {
    fill: $color2;
    stroke: $color1;
  }
}

@mixin iconsearch($color1, $color2) {
  & circle {
    fill: #ffffff00;
    stroke: $color1;
  }

  & path {
    stroke: $color1;
  }
}

@mixin iconsettings($color1, $color2) {
  & path {
    fill: none;
    stroke: $color1;
  }
}

@mixin iconexpand($color1, $color2) {
  & path {
    stroke: $color1;
  }
}

@mixin iconquestionsmall($color1, $color2) {
  & circle {
    fill: none;
    stroke: $color1;
  }
}

@mixin iconadd($color1, $color2) {
  & circle {
    fill: none;
    stroke: none;
  }

  & rect {
    fill: $color1;
  }
}

@mixin instruction($color1, $color2, $is_active: false) {
  @if ($is_active) {
    & path {
      fill: none;
      stroke: $color1;
    }
  }
}
@mixin health($color1, $color2) {
  & path:last-of-type {
    stroke: $color2;
  }
}
@mixin heartz($color1, $color2) {
  & path:last-of-type {
    stroke: $color2;
  }
}
@mixin hourglass($color1, $color2) {
  & path {
    fill: none;
  }

  & > :last-child {
    stroke: $color1;
  }
}
@mixin letter($color1, $color2) {
  & path {
    fill: none;
    stroke: $color2;
  }
  & rect {
    stroke: none;
    fill: $color1;
  }
}
@mixin libra($color1, $color2) {
  & path:last-of-type {
    fill: none;
    stroke: $color1;
  }
}
@mixin medal($color1, $color2) {
  & path:last-of-type {
    stroke: $color2;
  }
  & circle:first-of-type {
    fill: $color2;
  }
  & circle:last-of-type {
    fill: $color1;
  }
}
@mixin palmtree($color1, $color2) {
  & path {
    stroke: none;
    fill: $color1;
  }

  & > :first-child,
  & > :last-child {
    stroke: $color1;
  }
}
@mixin personcard($color1, $color2) {
  & path,
  & circle,
  & rect {
    fill: $color2;
  }
  & rect:first-of-type {
    fill: $color1;
  }
}
@mixin personcheck($color1, $color2) {
  & path:last-of-type {
    fill: none;
    stroke: $color1;
  }
}
@mixin road($color1, $color2) {
  & path:last-child {
    stroke: $color1;
  }
}
@mixin shield($color1, $color2) {
  & path:first-of-type {
    fill: $color2;
    stroke: $color1;
  }
  & path:last-of-type {
    stroke: $color1;
  }
}
@mixin statistics($color1, $color2) {
  & > :nth-child(3) {
    fill: none;
    stroke: $color1;
  }
}
@mixin ticket($color1, $color2) {
  & path:last-of-type {
    fill: none;
    stroke: $color2;
  }
}
@mixin unload($color1, $color2) {
  & > :nth-child(4),
  & > :nth-child(5) {
    fill: $color2;
  }
}

@mixin without_fill($color1, $color2) {
  & path,
  & circle,
  & rect {
    fill: none;
    stroke: $color1;
  }
}

$icon_list: "appsettings", "arrows", "attention", "coins", "circlecheck",
  "delta", "dontсall", "iconarrowleft", "iconcalendar", "iconcheck",
  "iconcollapsestroke", "iconfinance", "iconhistory", "iconexpand",
  "iconprinter", "iconsettings", "iconquestionsmall", "iconleft", "iconright",
  "iconadd", "iconsearch", "instruction", "health", "heartz", "hourglass",
  "letter", "libra", "medal", "palm tree", "personcard", "personcheck", "road",
  "shield", "statistics", "ticket", "unload";

.small {
  width: 24px;
  height: 24px;
  & svg {
    width: 18px !important;
    height: 18px !important;
  }
}

.icon {
  @each $name, $color in $colors_list {
    &_#{$name} {
      width: 24px;
      height: 24px;
      & svg {
        width: 24px;
        height: 24px;
        & path,
        & circle,
        & rect {
          fill: $color;
        }

        & mask {
          & path,
          & circle,
          & rect {
            fill: inherit;
          }
        }
      }
      @each $icon_name in $icon_list {
        & div[data-svg="#{$icon_name}"] svg {
          @if ($icon_name == "appsettings") {
            @include appsettings($color, $bw-white);
          } @else if $icon_name == "arrows" {
            @include arrows($color, $bw-white);
          } @else if ($icon_name == "attention") {
            @include attention($color, $bw-white);
          } @else if ($icon_name == "circlecheck") {
            @include circlecheck($color, $bw-white);
          } @else if ($icon_name == "coins") {
            @include coins($color, $bw-white);
          } @else if ($icon_name == "delta") {
            @include delta($color, $bw-white);
          } @else if ($icon_name == "dontсall") {
            @include dontсall($color, $bw-white);
          } @else if($icon_name == "iconarrowleft") {
            @include iconarrowleft($color, $bw-white);
          } @else if($icon_name == "iconexpand") {
            @include iconexpand($color, $bw-white);
          } @else if($icon_name == "iconcollapsestroke") {
            @include iconcollapsestroke($color, $bw-white);
          } @else if($icon_name == "iconhistory") {
            @include iconhistory($color, $bw-white);
          } @else if($icon_name == "iconcalendar") {
            @include iconcalendar($color, $bw-white);
          } @else if($icon_name == "iconcheck") {
            @include iconcheck($color, $bw-white);
          } @else if($icon_name == "iconfinance") {
            @include iconfinance($color, $bw-white, color-level($name, 2));
          } @else if($icon_name == "iconprinter") {
            @include iconprinter($color, $bw-white);
          } @else if($icon_name == "iconsearch") {
            @include iconsearch($color, $bw-white);
          } @else if($icon_name == "iconsettings") {
            @include iconsettings($color, $bw-white);
          } @else if($icon_name == "iconquestionsmall") {
            @include iconquestionsmall($color, $bw-white);
          } @else if($icon_name == "iconleft" or $icon_name == "iconright") {
            @include without_fill($color, $bw-white);
          } @else if($icon_name == "iconadd") {
            @include iconadd($color, $bw-white);
          } @else if ($icon_name == "instruction") {
            @include instruction($color, $bw-white);
          } @else if ($icon_name == "health") {
            @include health($color, $bw-white);
          } @else if ($icon_name == "heartz") {
            @include heartz($color, $bw-white);
          } @else if($icon_name == "hourglass") {
            @include hourglass($color, $bw-white);
          } @else if ($icon_name == "letter") {
            @include letter($color, $bw-white);
          } @else if ($icon_name == "libra") {
            @include libra($color, $bw-white);
          } @else if ($icon_name == "medal") {
            @include medal($color, $bw-white);
          } @else if ($icon_name == "palm tree") {
            @include palmtree($color, $bw-white);
          } @else if ($icon_name == "personcard") {
            @include personcard($color, $bw-white);
          } @else if ($icon_name == "personcheck") {
            @include personcheck($color, $bw-white);
          } @else if ($icon_name == "road") {
            @include road($color, $bw-white);
          } @else if ($icon_name == "shield") {
            @include shield($color, $bw-white);
          } @else if ($icon_name == "statistics") {
            @include statistics($color, $bw-white);
          } @else if ($icon_name == "ticket") {
            @include ticket($color, $bw-white);
          } @else if ($icon_name == "unload") {
            @include unload($color, $bw-white);
          }
        }
      }
    }
  }

  &_active {
    @each $name, $color in $colors_list {
      &_#{$name} {
        width: 24px;
        height: 24px;
        & svg {
          width: 24px;
          height: 24px;
          & path,
          & circle,
          & rect {
            fill: $bw-white;
          }
        }
        @each $icon_name in $icon_list {
          & div[data-svg="#{$icon_name}"] svg {
            @if ($icon_name == "appsettings") {
              @include appsettings($bw-white, $color);
            } @else if $icon_name == "arrows" {
              @include arrows($bw-white, $color);
            } @else if ($icon_name == "attention") {
              @include attention($bw-white, $color);
            } @else if ($icon_name == "coins") {
              @include coins($bw-white, $color);
            } @else if ($icon_name == "delta") {
              @include delta($bw-white, $color);
            } @else if ($icon_name == "dontсall") {
              @include dontсall($bw-white, $color);
            } @else if($icon_name == "iconarrowleft") {
              @include iconarrowleft($bw-white, $color);
            } @else if($icon_name == "iconexpand") {
              @include iconexpand($bw-white, $color);
            } @else if($icon_name == "iconcalendar") {
              @include iconcalendar($bw-white, $color);
            } @else if($icon_name == "iconcheck") {
              @include iconcheck($bw-white, $color);
            } @else if($icon_name == "iconcollapsestroke") {
              @include iconcollapsestroke($bw-white, $color);
            } @else if($icon_name == "iconfinance") {
              @include iconfinance($bw-white, $color, color-level($name, 2));
            } @else if($icon_name == "iconhistory") {
              @include iconhistory($bw-white, $color);
            } @else if($icon_name == "iconprinter") {
              @include iconprinter($bw-white, $color);
            } @else if($icon_name == "iconsearch") {
              @include iconsearch($bw-white, $color);
            } @else if($icon_name == "iconsettings") {
              @include iconsettings($bw-white, $color);
            } @else if($icon_name == "iconquestionsmall") {
              @include iconquestionsmall($bw-white, $color);
            } @else if($icon_name == "iconleft" or $icon_name == "iconright") {
              @include without_fill($bw-white, $color);
            } @else if($icon_name == "iconadd") {
              @include iconadd($bw-white, $color);
            } @else if ($icon_name == "instruction") {
              @include instruction($bw-white, $color, true);
            } @else if ($icon_name == "health") {
              @include health($bw-white, $color);
            } @else if ($icon_name == "heartz") {
              @include heartz($bw-white, $color);
            } @else if($icon_name == "hourglass") {
              @include hourglass($bw-white, $color);
            } @else if ($icon_name == "letter") {
              @include letter($bw-white, $color);
            } @else if ($icon_name == "libra") {
              @include libra($bw-white, $color);
            } @else if ($icon_name == "medal") {
              @include medal($bw-white, $color);
            } @else if ($icon_name == "palm tree") {
              @include palmtree($bw-white, $color);
            } @else if ($icon_name == "personcard") {
              @include personcard($bw-white, $color);
            } @else if ($icon_name == "personcheck") {
              @include personcheck($bw-white, $color);
            } @else if ($icon_name == "road") {
              @include road($bw-white, $color);
            } @else if ($icon_name == "shield") {
              @include shield($bw-white, $color);
            } @else if ($icon_name == "statistics") {
              @include statistics($bw-white, $color);
            } @else if ($icon_name == "ticket") {
              @include ticket($bw-white, $color);
            } @else if ($icon_name == "unload") {
              @include unload($bw-white, $color);
            }
          }
        }
      }
    }
  }
}
