.form {
  height: 100%;

  &__fields {
    column-count: 2;
    column-gap: 40px;
  }

  & > :last-child {
    margin: 40px 0 10px 0;

    & button {
      padding-left: 20px;
      padding-right: 20px;

      @media screen and (min-width: 1900px) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}
