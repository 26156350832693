@import "src/shared/assets/styles/main.scss";

.row {
  background-color: $bw-white;
  min-height: 44px !important;
  max-height: 44px !important;
  height: 44px !important;
  th:not(:first-child) {
    border: 1px solid $bw-gray3;
    vertical-align: middle;
    padding: 8px;
    min-width: 150px;
    & > p {
      @include zero;
      @include listMainText;
    }
  }
  th:nth-child(2) {
    min-width: 200px !important;
    padding-left: 74px;
  }

  &_withPadding {
    th:nth-child(2) {
      padding-left: 95px;
    }
  }

  &__iconBlock {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: $bw-white !important;
    border-left: 1px solid $bw-gray3;
    border-top: 1px solid $bw-gray3;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    width: 48px !important;
    padding: 8px 7px 8px 8px !important;
  }

  &__icon {
    transform: rotate(90deg);
  }
}
