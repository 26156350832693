@import "src/shared/assets/styles/main.scss";

%right-position {
  left: calc(110vw - 1px);

  & svg {
    transform: rotate(180deg);
  }
}

.todayBtn {
  position: absolute;
  top: 1px;
  left: 48px;
  right: 0;
  height: 44px;
  z-index: 2;
  pointer-events: none;

  &__container {
    position: sticky;
    left: 48px;
    top: 10px;
    height: 95%;
    border: none;
    border-left: 1px solid $bw-gray3;
    border-right: 1px solid $bw-gray3;
    padding: 5px;
    background-color: $bw-white;
    pointer-events: all;
    &_hover:hover {
      background-color: $blue-white-blue;
    }
    &_right {
      @extend %right-position;
    }
    & p {
      @include zero;
      @include listMainText;
    }

    & svg {
      margin-top: -4px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    &_right {
      @extend %right-position;
    }
  }

  &__text {
    @include zero;
    @include mainText;
    &_active {
      color: $blue-lazure !important;
    }
  }
}
