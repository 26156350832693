@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

.table {
  @include mainText;
  border-top: 1px solid $bw-gray2;
  margin-top: 30px;

  & > thead {
    background-color: $bw-white;
    position: sticky;
    z-index: 1;
    top: -2px;
  }

  & th {
    padding: 8px 0 8px 20px;
  }
}

.shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  transform: box-shadow 0.4s ease-in-out;
}

.tableHead {
  @include caption;
  white-space: nowrap;
  color: $bw-gray5;
  cursor: pointer;
}

.tableBody {
  border-top: none !important;

  & td {
    padding: 20px 10px;
  }

  &:first-child {
    padding-left: 20px !important;
  }
}

.tableRow {
  cursor: pointer;
  &:hover {
    background: $blue-light-blue;
    border-top: 1px solid $blue-light-blue;
    border-bottom: 1px solid $blue-light-blue;
  }
}

.tn {
  color: $blue-lazure;
  margin: 4px 0 8px;
}

.company {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 300px;
  gap: 20px;
  margin-bottom: 4px;
}

.candidateBirthday {
  display: flex;
  justify-content: space-between;
}

.birthday {
  color: $bw-gray6;
}

.notStaff {
  @each $name, $color in $colors-list {
    &_#{$name} {
      @include squareChip($color);
      margin-top: 8px;
    }
  }
}

.shifts {
  @include caption;
  white-space: nowrap;
  color: $bw-gray5;
  padding: 0 20px 5px 0;
}

.quantityOfShifts {
  text-align: right;
  margin-right: 20px;
}

.error {
  & td {
    border-bottom: none;
  }
}

.loading {
  @include listMainText;
}
