@import "shared/assets/styles/main.scss";

.form {
  height: 100%;

  &__fields {
    width: 55%;
    @media screen and (min-width: 1400px) {
      width: 45%;
    }
    @media screen and (min-width: 1900px) {
      width: 750px;
    }
  }

  & > :last-child {
    margin: 40px 0 10px 0;

    & button {
      padding-left: 20px;
      padding-right: 20px;
      width: 200px;

      @media screen and (min-width: 1900px) {
        padding-left: 30px;
        padding-right: 30px;
        width: 240px;
      }
    }
  }
}

.errors {
  margin-top: 40px;
  margin-bottom: -20px;
  width: 52%;
  @media screen and (min-width: 1400px) {
    width: 42%;
  }
  @media screen and (min-width: 1900px) {
    width: 700px;
  }

  & div {
    @include listMainText;
    font-weight: 500;
  }
}
