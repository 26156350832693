@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";

%iconButton {
  display: flex;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;

  rect {
    fill: $bw-black;
  }

  &:hover {
    border: 1px solid $bw-gray3;
  }

  &:active {
    background-color: $bw-gray7;

    rect {
      fill: $bw-white;
    }
  }

  &:disabled {
    & rect {
      fill: $bw-gray4;
      background-color: transparent;
    }

    color: $bw-gray4;

    &:hover {
      border: none;
    }

    &:active {
      background-color: transparent;
      border: none;
      color: $bw-gray4;

      rect {
        fill: $bw-gray4;
      }
    }
  }
}

.cropper {
  width: 510px;
  height: 640px;

  & > div {
    background-color: $bw-gray4;
    margin: 30px 0;
  }
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.buttonsBox {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  margin: 0 auto;
}

.buttonsBoxSmall {
  width: 150px;
}

.iconRotate {
  @extend %iconButton;

  & path {
    fill: $bw-gray7;
  }

  &:active {
    background-color: $bw-gray7;

    & path {
      fill: $bw-white;
    }
  }

  transform: scale(-1, 1);
}

.iconMirror {
  @extend %iconButton;
  stroke: $bw-gray7;

  &:active {
    background-color: $bw-gray7;
    stroke: $bw-white;
  }
}

.iconMinus,
.iconPlus {
  @extend %iconButton;

  & rect {
    fill: $bw-gray7;
  }
}

.iconReset {
  @extend %iconButton;
  width: 80px;
  border-radius: 4px;
  color: $bw-gray7;

  &:active {
    color: $bw-white;
  }
}

.scale {
  width: 40px;
  border: none;
  background-color: $bw-gray1;
  color: $bw-black;
}

.tooltip {
  position: relative;
  @include tooltip("top", $bw-gray1, $bw-gray6, $bw-gray6);
  transform: none !important;
}
