@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.addPlaceBorder {
  display: flex;
  align-items: center;
  text-align: center;
  width: 550px;
  height: 448px;
  margin: 70px auto 152px;
  border-radius: 20px;
  border: 1px dashed $bw-gray3;
  cursor: pointer;
}

.dropzone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconAddWrapper {
  margin-bottom: 30px;
}

.iconAdd {
  position: relative;
  width: 50px;
  margin: 0 auto;
}

.iconAddFotoDefault {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.iconAddWithoutFill {
  fill: $bw-gray5;
  position: absolute;
  left: 29px;
  top: -9px;
}

svg.iconAddWithoutFill rect {
  fill: $bw-white;
}

.addPlaceText {
  @include mainText;
  line-height: 17px;
  color: $bw-gray4;
  width: 250px;
  text-align: center;
  margin: 30px auto 0;
}

.uploadBtnBox {
  padding-bottom: 50px;
}

.uploadBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 40px;
  background-color: $bw-gray7;
  color: $bw-white;
  border: none;
  border-radius: 20px;

  &:disabled {
    background-color: $bw-gray6;
    color: $bw-gray4;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
}
