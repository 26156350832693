@import "src/shared/assets/styles/base/colors.scss";

.viewingSection {
  width: 100%;
  height: 100%;
}

.prevNextBtnBox {
  display: flex;
  justify-content: space-around;
  width: 720px;
}

.prevBtn,
.nextBtn {
  height: 300px;
  width: 50px;
  border: none;
  border-radius: 16px 0 0 16px;
  margin: auto 0;
  background-color: $bw-gray2;
  stroke: $blue-lazure;
}

.prevBtnDisabled,
.nextBtnDisabled {
  stroke: $bw-gray4;
}

.nextBtnImg {
  transform: rotate(180deg)
}

.galleryPhotoBackground {
  width: 570px;
  height: 636px;
  margin: 50px auto;
}

.iconsGalleryBox {
  width: 570px;
  height: 687px;

  & > div > div {
    margin: 0 auto 50px;
  }
}
