@import "~/src/shared/assets/styles/main.scss";

.dismissCompanyItem {
  display: flex;
  column-gap: 17px;
  @include mainText;
  padding: 9px 4px 12px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $bw-gray3;
  }

  &_hover {
    &:hover {
      background-color: $blue-white-blue;
      cursor: default;
    }
  }
}

.dismissCompanyInfoShort {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;

  &_header {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
}

.statusListBtn {
  @include squareChip($bw-gray7);
  padding: 4px 12px;
  margin-right: 4px;

  &_gray {
    @extend .statusListBtn;
    background-color: $bw-gray6;
  }
}

.buildingTitle {
  @include zero;
  text-decoration: none;
  cursor: pointer;
  color: $blue-lazure;
}

.blueBtn {
  color: $blue-lazure;
  cursor: pointer;
}

.dismissCompanyInfoFull {
  height: 100%;
  width: 100%;
  margin-top: -12px;
}

.firstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $bw-gray3;
  padding-right: 4px;
  &:hover {
    background-color: $bw-gray1;
  }
}

.valueRow {
  display: flex;
  column-gap: 20px;

  & > div:first-child {
    padding-left: 4px;
  }
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 0;
}

.fieldTitle {
  @include zero;
  @include caption;
  height: 14px;
  white-space: nowrap;
  color: $bw-gray6;
}

.fieldValue {
  @include zero;
  @include mainText;
  word-break: break-word;
  padding-bottom: 2.5px;
}
