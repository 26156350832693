@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";


.container {
  width: 100%;
  max-width: 976px;
  @include mainText;
}

.title {
  @include subtitle;
  text-align: left;
  margin-bottom: 15px;
}

.borderBottom {
  min-height: 65px;
  border-bottom: 1px solid $bw-gray2;
  padding: 12px 0;
}

.row {
  display: flex;
  justify-content: flex-start;
}

.col {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}

.columns {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.column {
  margin-left: 38px;
}

.eventsList {
  @include zero;
  margin-left: 25px;
}

.listItem {
  padding: 10px 0 12px 15px;
  border-bottom: 1px solid $bw-gray2;
  &:last-child {
    border-bottom: none;
  }
}

.subtitle {
  @include caption;
  margin-bottom: 8px;
}

.tn {
  color: $blue-lazure;
  margin-right: 10px;
  cursor: pointer;
  text-decoration: none;
}

.status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.candidateBirthday {
  max-width: 161px;
  display: flex;
}

.birthday {
  color: $bw-gray6;
  margin-left: 20px;
}

.notStaff {
  @each $name, $color in $colors-list {
    &_#{$name} {
      @include squareChip($color);
      margin-top: 8px;
    }
  }
}
