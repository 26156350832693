@import "/src/shared/assets/styles/base/colors.scss";

.search {
  display: grid;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  height: max-content;

  & > :first-child,
  & input {
    width: 100%;
  }

  & > :first-child > :nth-child(2) {
    left: calc(100% - 35px);
  }
}

.withSort {
  grid-template-columns: 1fr 40px;
}

.iconSort {
  cursor: pointer;
  border: 1px solid $blue-azure;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & > svg {
    width: 24px;

    & path {
      fill: $bw-gray7;
    }
  }
}
