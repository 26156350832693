@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/mixins/tableMixins.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.table {
  border-top: 1px solid $bw-gray3;
  @include mainText;

  tbody,
  td,
  th,
  thead,
  tr,
  .empty {
    border: none;
  }

  td {
    position: relative;
    border-top: 1px solid $bw-gray3;
    & a:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  tbody td {
    padding: 0 !important;
    & > :first-child {
      margin: 0 !important;
    }

    & > div[data-type="error"] {
      margin: 20px !important;
    }
  }

  tbody:hover tr {
    background-color: $blue-white-blue;
    cursor: pointer;

    & td {
      background-color: $blue-white-blue;
      &:after {
        background-color: $blue-white-blue !important;
      }
    }
  }

  @media screen and (max-width: 1500px) {
    & td,
    & th {
      padding-left: 5px !important;
      padding-right: 0 !important;
    }
  }
}

.row {
  @each $name, $color in $colors_list {
    &_#{$name} {
      background-color: $color;
      @include deleteExtraBorder($color, $blue-white-blue);

      @if ($name == "bw-gray4" or $name == "bw-gray5") {
        background-color: $color !important;
        @include deleteExtraBorder($color, $blue-white-blue, true);
      }
    }
  }
}

.wrapper {
  @include zero;
  padding: 20px 0.5rem;
  display: flex;
  align-items: center;
  min-height: 24px;

  & > p {
    margin: 0;
  }
}

.birthday {
  color: $bw-gray6;
  margin-left: 10px;
}

.wrapperZero {
  @extend .wrapper;
  @include zero;
}

.wrapperBlock {
  @include zero;
  padding: 20px 0.5rem;
  min-height: 24px;
  width: max-content;
}

.value {
  cursor: auto;
  padding: 5px;
}

.countWorkAll {
  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.title {
  @include caption;
  background-color: $bw-white !important;
  white-space: nowrap;
  background-clip: padding-box;
}

.sortedIcon {
  display: flex;
  align-items: center;
  & svg {
    margin-top: -2px;
  }
  & .titleText {
    margin: 0;
  }
}

.nonSorted {
  display: flex;
  align-items: center;
  cursor: default;
  & .nonSortedText {
    margin: 0;
  }
}

.pointer {
  cursor: pointer;
}

th.title div {
  height: 20px;
}

.error {
  & td {
    border-bottom: none;
    & .emptyAlert {
      padding: 20px !important;
      height: 51px;
      width: max-content;
    }
  }
}

.tN {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.gray {
  background-color: $bw-gray6 !important;
}

.maxContent {
  width: max-content;
  & > a,
  & > div,
  & > p {
    width: max-content;
  }

  & > a {
    flex-direction: column;
  }
}

.maxContentWrapper {
  display: flex;
  gap: 4px;
}

.withOkIcon {
  justify-content: center;
}

.loading {
  position: relative;
  height: 50px;
  & > div {
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.linkToBuilding {
  cursor: auto;
  text-decoration: none;
  color: $blue-lazure;
  &:hover {
    color: $blue-lazure;
  }
}
