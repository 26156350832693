@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.tabs {
  width: 100%;
  border-bottom: 1px solid $bw-gray3;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  & div[data-type="tooltip"] {
    .button {
      margin-top: 3px !important;
      margin-left: 2px !important;
    }
  }
}

.tabLinkContainer {
  background-color: $bw-gray2;
  border-radius: 8px 8px 0 0;
  position: relative;
  padding: 0 3px 2px 3px;
  margin: 0 3px -1px 0;
  border-bottom: 1px solid $bw-gray3;
}

.activeContainer {
  padding-bottom: 5px;
  margin: 0 0 -1px -3px;
  border-bottom: 1px solid $bw-white;
  background-color: $bw-white;
}

.tabLinks {
  @include mainText;
  position: sticky;
  border: none;
  background-color: $bw-gray2;
  color: $bw-gray6;
  border-radius: 8px 8px 0 0;
  padding: 10px 10px 6px 10px;
}

.tabLinksClipped {
  @include clippedText;
  max-width: 90px;
}

.active {
  color: $blue-blue;
  background-color: $bw-white;
  position: relative;
  border: 1px solid $bw-gray3;
  border-bottom: 1px solid $bw-white;
  margin-bottom: -2px;
}
.active:after,
.active:before {
  content: "";
  width: 10px;
  height: 20px;
  border: 1px solid $bw-gray3;
  position: absolute;
  border-top: 0;
}

.active:after {
  border-left: 0;
  border-radius: 0 0 5px 0;
  left: -10px;
  bottom: -4px;
}

.active:before {
  border-right: 0;
  border-radius: 0 0 0 5px;
  right: -10px;
  bottom: -4px;
}

.tabLinkContainer_0 {
  padding-left: 0;
}

.tabLink_0:after {
  width: 0;
  left: -1px;
  bottom: -4px;
}

.activeContainer_0 {
  margin: 0 0 -1px 0;
}
