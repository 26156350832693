@import "src/shared/assets/styles/base/colors.scss";

.photosGalleryWrapper {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  width: fit-content;

  @media screen and (max-width: 1900px) {
    width: 250px !important;
  }
}

.photoWrapper {
  display: flex;
  align-items: flex-end;
  border-radius: 20px;
  position: relative;
  margin: 0 20px 20px 0;

  &:hover {
    outline: 2px solid $blue-lazure;
    cursor: pointer;
  }
}

.photoWrapperDisabled {
  outline: 2px solid $blue-lazure;
  opacity: 0.5;
}

.photo {
  width: 230px;
  height: 230px;
  border-radius: 20px;
  object-fit: cover;
  cursor: pointer;
}

.iconClose {
  background: black;
  opacity: 0.3;
  border-radius: 20px;
  position: absolute;
  right: 8px;
  top: 8px;

  &:hover {
    background: $blue-lazure;
    opacity: 1;
  }
}

.iconStar {
  position: absolute;
  left: 4px;
  bottom: 4px;
}

.placeForAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 230px;
  background-color: $bw-gray1;
  border: 1px dashed $bw-gray3;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    border: 2px solid $blue-lazure;
  }
}

.iconAddFotoDefault {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.iconAddWithoutFill {
  fill: $bw-gray5;
  position: absolute;
  left: 29px;
  top: -9px;

  & rect {
    fill: $bw-white;
  }
}

.positionRelative {
  position: relative;
}
