@import "src/shared/assets/styles/base/colors.scss";

.buttonBox {
  width: 520px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.saveBtn,
.cancelBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 40px;
  background-color: $bw-gray7;
  color: $bw-white;
  border: none;
  border-radius: 20px;

  &:disabled {
    background-color: $bw-gray6;
    color: $bw-gray4;
  }
}

.iconClose {
  fill: $bw-white;
}
