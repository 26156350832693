@import "/src/shared/assets/styles/base/colors.scss";

.scrollboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  row-gap: 16px;
  padding: 20px 16px;
  border: 1px solid $blue-azure;
  background-color: $bw-white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  border-radius: 8px;

  // для firefox нужно спрятать вертикальный скролл
  & > :first-child > :first-child {
    @-moz-document url-prefix() {
      margin-right: -19px !important;
    }
  }
  position: relative;
  & div[data-loading="true"] {
    position: absolute;
    display: block;
    left: 0;

    & > div {
      margin-top: 40px;
    }
  }
}

.scrollboardWithoutPadding {
  padding: 10px;

  // для firefox нужно спрятать вертикальный скролл
  & > :nth-child(2) > :first-child {
    @-moz-document url-prefix() {
      margin-right: -19px !important;
    }
  }
}

.emptyAlert {
  width: 100%;
}
