@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";

.button {
  @include mainText;
  cursor: pointer;
  border-radius: 6px;
  border: none;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  height: 40px;

  p {
    margin: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.primary {
  padding: 11px 20px;
  color: $bw-white;
  background-color: $blue-lazure;

  &:hover,
  &:focus {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12),
      0px 4px 16px rgba(0, 140, 255, 0.4);
  }

  &:active,
  &:active:focus {
    box-shadow: inset 4px -4px 4px rgba(255, 255, 255, 0.5),
      inset -4px 4px 4px rgba(255, 255, 255, 0.5);
  }

  &:disabled {
    color: $bw-white;
    background-color: $blue-light-blue;
  }
}

.secondary {
  padding: 11px 20px;
  color: $blue-lazure;
  background-color: $bw-gray2;

  &:hover,
  &:active {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
      0px 2px 8px rgba(0, 140, 255, 0.1);
  }

  &:active {
    background-color: $bw-gray3;
  }

  &:disabled {
    color: $bw-gray4;
    background-color: $bw-gray2;
  }
}

.cancelled {
  @extend .secondary;
  color: $accent-orange;
}

.floating {
  column-gap: 6px;
  padding: 10px 16px 10px 14px;
  border-radius: 20px;
  color: $bw-white;
  background-color: $blue-lazure;

  &:hover,
  &:focus {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12),
      0px 4px 16px rgba(0, 140, 255, 0.4);
  }

  &:active,
  &:active:focus {
    box-shadow: inset 4px -4px 4px rgba(255, 255, 255, 0.5),
      inset -4px 4px 4px rgba(255, 255, 255, 0.5);
  }

  &:disabled {
    background: $blue-azure;
  }

  @media screen and (max-width: 1200px) {
    padding: 8px 16px;
    & p:last-child {
      display: none;
    }
  }
}

.clear {
  border: none;
  background-color: transparent;
  text-decoration: none;
  color: $blue-lazure;
  height: max-content;
  column-gap: 4px;

  &:hover,
  &:focus {
    font-weight: 500;
  }

  &:active,
  &:active:focus {
    background-color: $blue-white-blue;
    border-radius: 16px;
    padding: 4px 12px 7px;
  }

  &:disabled {
    color: $bw-gray5;
  }
}

.round {
  background-color: $bw-gray2;
  border-radius: 50%;
  height: max-content;
  padding: 0;
  &:hover {
    background-color: $bw-gray3;
  }

  &:disabled {
    &:hover {
      background-color: $bw-gray2;
    }
  }
}

.size_l {
  min-width: 200px;
  width: max-content;
}

.size_m {
  font-size: 14px;
  padding: 0px 20px;
  height: 30px;
}

.size_s {
  font-size: 14px;
  padding: 0;
  width: 36px;
  height: 36px;
}

.tab {
  border: none;
  outline: none;
  @include zero;
  @include listMainText;
  cursor: pointer;
  width: max-content;
  height: max-content;
  padding: 11px 18px;
  margin: 0;
  border-radius: 8px;
  color: $bw-gray7;
  background: $bw-gray2;

  &:hover {
    background: $bw-white;
    box-shadow: 0px 4px 10px rgba(121, 73, 73, 0.1);
    transition: all 0.4s ease;
  }

  &:focus {
    background: $bw-white;
    box-shadow: 0px 4px 10px rgba(121, 73, 73, 0.1);
    transition: all 0.4s ease;
  }

  &:disabled {
    cursor: default;
    background: $bw-gray1;
    color: $bw-gray4;

    &:hover {
      box-shadow: none;
    }
  }
}

.tabActive {
  @include selectedButton;
}

.withLabel {
  padding: 12px 18px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  @include caption;
  @include zero;
  height: 19px;
  text-align: start;
}
